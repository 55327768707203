import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, OverlayTrigger, Popover, FormControl } from 'react-bootstrap';
import SharedUpdateModal from './SharedUpdateModal';
import { deleteSharedForm, fetchSharedData, postSharedData } from '../action';
import DataTable from '../../common/components/DataTable';
import { formateDate } from '../../../lib/utils';
import { copyTextToClipboard } from '../../common/action';
import Breadcrumbs from '../../common/components/Breadcrumbs';

class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            start_time: "",
            end_time: "",
            permission: "",
            scope: "",
            showUpdateModal: false,
            selectedData: null,
            linkToCopy: "", // State to hold the link
        }
    }

    componentDidMount() {
        this.props.fetchSharedData(this.props.token.access, this.props.params.id);
    }
    handleCopyEmbedCode(row) {
        const embedCode = `<iframe width="100%" height="600px" src="${window.location.protocol}//${window.location.host}/forms/public/${row.token}/${row.template}" title="gigaforms" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
        this.props.copyText(embedCode);
        alert("Embedded code copied to clipboard!");
    }
    
    handleCreateAction(e) {
        let requestBody = {
            "label": this.state.label,
            "start_time": this.state.start_time,
            "end_time": this.state.end_time,
            "permission": this.state.permission,
            "scope": this.state.scope,
            "template": this.props.params.id
        }
        this.props.postSharedData(this.props.token.access, requestBody, this.props.params.id);
        this.setState({
            label: "",
            description: "",
            start_time: "",
            end_time: "",
            permission: "",
            scope: ""
        })
    }

    handleDeleteAction(id) {
        this.props.deleteSharedForm(this.props.token.access, id, this.props.params.id);
    }

    handleCopyLink(row) {
        const link = `${window.location.protocol}//${window.location.host}/forms/public/${row.token}/${row.template}`;
        this.setState({ linkToCopy: link }); // Set the link to show in the popover
    }

    render() {
        const { linkToCopy } = this.state;

        const copyLinkPopover = (
            <Popover id="copy-link-popover" style={{ width: 'auto', minWidth: '300px' }}>
    <Popover.Header as="h3">Copy The Share Link</Popover.Header>
    <Popover.Body>
        <Row className="align-items-center">
            <Col xs={8} className="pr-1">
                <FormControl
                    value={linkToCopy}
                    readOnly
                    size="sm"
                    style={{ width: '100%' }}
                />
            </Col>
            <Col xs={4} className="pl-1">
                <Button 
                    variant="success"
                    size="sm"
                    onClick={() => {
                        this.props.copyText(linkToCopy);
                        alert('Link copied to clipboard!');
                    }}
                    className="w-100"
                >
                    <i className="fa fa-copy" aria-hidden="true" style={{ fontSize: "15px", color: "#fff", padding: "2px 3px" }}></i>
                </Button>
            </Col>
        </Row>
    </Popover.Body>
</Popover>
        );

        return (
            <div className="main-content-container p-4 container-fluid">
                <div className='mb-3'>
                    <Breadcrumbs name={this.props} disabledStrings={['template', this.props.params.id]} />
                </div>
                <Row>
                    <Col>
                        <p>Share</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => {
                                    this.handleCreateAction(e);
                                    e.preventDefault();
                                }}>
                                    <Row>
                                        <Col className='m-3'>
                                            <Form.Label>Label<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Template Name" required value={this.state.label}
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>

                                        <Col className='m-3'>
                                            <Form.Label>Start Time <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="datetime-local" placeholder="Enter Start Time" required value={this.state.start_time}
                                                onChange={event => { this.setState({ start_time: event.target.value }); }} />
                                        </Col>

                                        <Col className='m-3'>
                                            <Form.Label>End Time <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="datetime-local" placeholder="Enter End Time" required value={this.state.end_time}
                                                onChange={event => { this.setState({ end_time: event.target.value }); }} />
                                        </Col>
                                        <Col className='m-3'>
                                            <Form.Label>Permission<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select size="sm" placeholder='Select Permission'
                                                value={this.state.permission} onChange={event => { this.setState({ permission: event.target.value }); }}>
                                                <option value="" disabled hidden>Select Permission</option>
                                                <option value={"ANYONE_WITH_LINK"}>Anyone With Link</option>
                                                <option value={"RESTRICTED"}>Restricted</option>
                                            </Form.Select>
                                        </Col>
                                        <Col className='m-3'>
                                            <Form.Label>Scope<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select size="sm" placeholder='Select Scope'
                                                value={this.state.scope} onChange={event => { this.setState({ scope: event.target.value }); }}>
                                                <option value="" disabled hidden>Select Scope</option>
                                                <option value={"ADD"}>Add</option>
                                                <option value={"VIEW"}>View</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='m-3'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className='mt-5 font-medium'>
                                    <DataTable
                                        totalItemsCount={this.props.sharedData.count}
                                        headers={['Label', 'Start Time', 'End Time', 'Permission', 'Scope', 'Last Modified', 'Action']}
                                        onPageChange={(pageNumber) => this.props.getFormList(this.props.token.access, pageNumber)}
                                        body={this.props.sharedData.results.map((row, index) => {
                                            return [
                                                row.label,
                                                formateDate(row.start_time),
                                                formateDate(row.end_time),
                                                row.permission,
                                                row.scope,
                                                formateDate(row.date_updated),
                                                <Row>
                                                    <Col sm='12'>
                                                    <OverlayTrigger
    trigger="click"
    placement="bottom"
    overlay={copyLinkPopover}
>
    <Button variant="success" size='sm' className="m-1"
        onClick={() => this.handleCopyLink(row)}>
        <i
                                        class="fa fa-clone"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "15px",
                                          color: "white",
                                          padding: "2px 3px",
                                        }}
                                      ></i>
    </Button>
</OverlayTrigger>
                                                        <Button variant="info" size='sm' className="m-1"
                                                            onClick={() => this.setState({ showUpdateModal: true, selectedData: row })}><i
                                                            class="fa fa-pencil-square-o"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "15px",
                                                              color: "white",
                                                              padding: "2px 3px",
                                                            }}
                                                          ></i></Button>
                                                        <Button variant="danger" size='sm' className="m-1"
                                                            onClick={() => { this.handleDeleteAction(row.id); }}>
                                                                <i
                                                            class="fa fa-trash-o"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "15px",
                                                              color: "white",
                                                              padding: "2px 3px",
                                                            }}
                                                          ></i>
                                                            </Button>
                                                            <Button
    variant="primary"
    size="sm"
    className="m-1"
    title="Embedded Code"
    onClick={() => this.handleCopyEmbedCode(row)}
>
    <i
        className="fa fa-code"
        aria-hidden="true"
        style={{
            fontSize: "15px",
            color: "white",
            padding: "2px 3px",
        }}
    ></i>
</Button>

                                                    </Col>
                                                </Row>
                                            ]
                                        })}
                                    />
                                </div>
                                <SharedUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} templateId={this.props.params.id} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sharedData: state.formsReducer.sharedData,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteSharedForm: (accessToken, shareId, templateId) => dispatch(deleteSharedForm(accessToken, shareId, templateId)),
        fetchSharedData: (accessToken, templateId) => dispatch(fetchSharedData(accessToken, templateId)),
        postSharedData: (accessToken, requestBody, templateId) => dispatch(postSharedData(accessToken, requestBody, templateId)),
        copyText: (copyText) => dispatch(copyTextToClipboard(copyText)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
