import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, Col, Row, Table } from "react-bootstrap";
import { getCustomDashboard } from "../users/action";
import { BUTTONS, dashboardIcon } from "../../lib/constants";
import { updateCustomDashboard } from "./action";
import { fetchFields, fetchForms } from "../forms/action";
import { fetchWorkflowStateList } from "../workflows/action";
import { setNotification } from "../common/action";

const CustomDashboardUpdateModal = (props) => {
  const [dashboardName, setDashboardName] = useState("");
  const [data, setData] = useState([]);
  const [fields, setFields] = useState({});
  const [tabledata, setTableData] = useState([]);
  const [tabledatashow, setTableDatashow] = useState([]);
  const [allfield, setallfield] = useState([]);
  const [localWorkflowStateList, setLocalWorkflowStateList] = useState([]);
  const [state, setState] = useState({
    name: '',
    type: '',
    template: '',
    field: '',
    function: '',
    states: '',
    dashboardname: {},
    showUpdateModal: false,
    buttonType: '',
    indivisual: '',
    icon: ''
  })
  const handleChange1 = (e) => {

    const { name, value } = e.target
    setState((prevstate) => ({
      ...prevstate,
      [name]: value
    }))
  }

  const fieldHandle1 = useCallback(async (formId, callback) => {
 
    try {
      // Fetch fields for the given formId
      const fieldsResponse = await props.getFields(props.token?.access, formId, 1);

      // Execute callback with the response data
      if (callback) {
        callback(fieldsResponse?.data || []);
      }
    } catch (error) {
      console.error("Error fetching fields:", error);

      // Execute callback with an empty array in case of error
      if (callback) {
        callback([]);
      }
    }

    // Optionally fetch the form
    try {
      await props.getForm(props.token?.access, formId);
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }, [props.token?.access]);

  useEffect(() => {
    if (Array.isArray(props.fields) && props.fields.length > 0) {
      setallfield(props.fields); // Update allfield whenever props.fields changes
    } else {
      setState((prevState) => ({
        ...prevState,
        field: '', // Set default field to the first item’s id
      }));
      setallfield([])
    }
  }, [props.fields]);


  useEffect(() => {
    if (props.form?.workflow) {
      props.fetchWorkflowStateList(props.token?.access, props.form.workflow);
    }
    else {
      setState((prevstate) => ({
        ...prevstate,
        states: ""
      }))
      setLocalWorkflowStateList([])
    }

  }, [props.form, props.token?.access]);

  useEffect(() => {
    if (props.workflowStateList?.results) {
      setLocalWorkflowStateList(props.workflowStateList.results);
    }
  }, [props.workflowStateList]);

  const addData = () => {
    const newData = [state.name, state.type, state.template, state.field, state.function, state.states, state.buttonType, state.icon, state.indivisual];
    console.log(newData, 'newData')
    if (state.name && state.type) {
      setTableData((prevTableData) => [...prevTableData, newData]);



      const selectedField = props.fields.find(field => field?.id == state.field);
     
      const templateName = props.formList?.data?.find(template => template.id === Number(state.template))?.label || '';
      const stateName = localWorkflowStateList.find(item => item.id === Number(state.states))?.label || '';

      const iconDisplay = dashboardIcon.find((ele) => ele.value === state.icon)?.icon || state.icon;
      const buttonLabel = BUTTONS.find((button) => button.value === state.buttonType)?.label || state.buttonType;
      // Prepare data for the table (store the name, type, template, field name, function, state)
      const newDatashow = [
        state.name,
        state.type,
        templateName,
        selectedField ? selectedField.label : '',  // Store the field name (label) in the table
        state.function,
        stateName,
        // state.buttonType,
        buttonLabel,
        iconDisplay,
        //  state.icon, 
         state.indivisual,

      ];
      setTableDatashow((prevTableData) => [...prevTableData, newDatashow]);
      setState({
        name: "",
        type: '',
        template: '',
        field: '',
        function: '',
        states: '',
        buttonType: '',
        indivisual: '',
        icon: ''
      });
      setallfield([]);
      setLocalWorkflowStateList([])
    } else {
      props.setNotification(true, "Please fill all fields.", "danger");
    }
  }

  const handleChange = (e, itemId, detailId) => {
    const { name, value } = e.target;

    // Step 1: Update the main data structure
    setData((prevData) => {
      return {
        ...prevData,
        results: prevData.results.map((item) => {

          if (item.id !== itemId) return item;

          // Update details within the specific item
          return {
            ...item,
            details: item.details.map((detail) =>
              detail.id === detailId
                ? { ...detail, [name]: value } // Update the specific field in detail
                : detail
            ),
          };
        }),
      };
    });

    // Step 2: Handle additional field logic via props
    if (name == 'template') {
      props.fieldHandle(value?.id, (fieldsResponse) => {

        setFields((prevFields) => ({
          ...prevFields,
          [detailId]: fieldsResponse, // Dynamically set fields for the specific detailId
        }));
      });
    }

  };




  useEffect(() => {
    if (props.token?.access && props.dashboardData?.results) {
      setData(props.dashboardData);

      // Preload fields for templates
      props.dashboardData.results.forEach((item) => {
        item.details.forEach((detail) => {
          if (detail.template?.id) {
            props.fieldHandle(detail.template.id, (fieldsResponse) => {

              setFields((prevFields) => ({
                ...prevFields,
                [detail.id]: fieldsResponse,
              }));
            });
          }
        });
      });
    }
  }, [props.token?.access, props.dashboardData]);

  useEffect(() => {
    if (props.token?.access && props.dashboardData?.results) {
      setData(props.dashboardData);



      // Preload fields for templates
      props.dashboardData.results.forEach((item) => {
        item.details.forEach((detail) => {
          if (detail.template?.id) {
            props.fieldHandle(detail.template.id, (fieldsResponse) => {
              setFields((prevFields) => ({
                ...prevFields,
                [detail.id]: fieldsResponse,
              }));
            });
          }
        });
      });
    }
  }, [props.token?.access, props.dashboardData]);

  useEffect(() => {
    setDashboardName(
      props.dashboardData?.results?.[0]?.dashboard_name || ""
    );
  }, [props.dashboardData]);
 
  const handleDelete = (itemId, detailId) => {
    console.log(itemId, detailId)
    setData((prevData) => {
      if (!prevData || !prevData.results) return prevData;
  
      return {
        ...prevData,
        results: prevData.results
          .map((item) => {
            if (item.id === itemId) {
              console.log(item.id ,itemId)
              return {
                ...item,
                details: item.details.filter((detail) => detail.id !== detailId),
              };
            }
            return item;
          })
          .filter((item) => item.details.length > 0), // Remove empty items
      };
    });
  };
  const handlenewdataDelete = (index) => {
    setTableData((prevTableData) => prevTableData.filter((_, i) => i !== index));
    setTableDatashow((prevTableData) => prevTableData.filter((_, i) => i !== index));
};
console.log('data',data)
  // const handleEditAction = (e, id) => {
  //   e.preventDefault();
  //   // const requestBody = {
  //   //   dashboard: { dashboard_name: dashboardName },
  //   //   details: data?.results?.flatMap((item) =>
  //   //     item.details.map((detail) => ({
  //   //       name: detail.name || "",
  //   //       type: detail.type || "Card",
  //   //       template: Number(detail.template?.id || 0),
  //   //       field: Number(detail.field?.id || 0),
  //   //       function: detail.function || "",
  //   //       state: Number(detail.state?.id || 0),
  //   //       colour:detail.colour,
  //   //       icon:detail.icon,
  //   //       individual:detail.individual,
  //   //     }))
  //   //   ),


  //   // };

  //   console.log('requestbody',data)
  //   const requestBody = {
  //     dashboard: {
  //       dashboard_name: dashboardName // Set the dashboard name
  //     },
  //     details: [
  //       // Combine the details from `data.results` and `tabledata`
  //       ...(data?.results?.flatMap((item) =>
  //         item.details.map((detail) => ({
  //           name: detail?.name? detail.name : "",
  //           type: detail?.type?  detail.type : "",
  //           template: detail?.template?.id ? Number(detail.template?.id) : "",
  //           field: detail?.field?.id ? Number(detail.field?.id) : "",
  //           function: detail?.function? detail.function : "",
  //           state: detail.state?.id ? Number(detail.state?.id) : "", 
  //           colour: detail?.colour? detail.colour : "",
  //           icon: detail?.icon? detail.icon : "",
  //           individual: detail?.individual? detail.individual : "",
  //         }))
  //       ) || []), // Fallback to an empty array if no results
  //       ...tabledata.map((data) => ({
  //         name: data[0]? data[0] : "",
  //         type: data[1]? data[1] : "",
  //         template: data[2]? Number(data[2]) : "",
  //         field: data[3] ? Number(data[3]) : "",
  //         function: data[4]? data[4] : "",
  //         state: data[5] ? Number(data[5]) : "",
  //         colour: data[6]? data[6] : "",
  //         icon: data[7]? data[7] : "",
  //         individual: data[8]? data[8] :"",
  //       }))
  //     ]
  //   };
  //   if (!dashboardName) {
  //     props.setNotification(true, "Please Enter Dashboard Name.", "danger");
  //     return; // Stop execution here
  // }
  //   if (dashboardName && requestBody.details.length > 0) {
   
  //     props.updateCustomDashboard(props.token?.access,id, requestBody,()=>{
  //       // this.setState({ showUpdateModal: false }); 
  //       // props.setNotification(true, "Data updated successfully!", "success");
  //       // setDashboardName(""); // Clear dashboard name
  //       // setTableData([]); // Clear table data
  //       setTableDatashow([]); // Clear display table data
  //       // setData(null); // Reset any fetched data
  //       props.handleClose();
  //     });
     
  //   } else {
  //     props.setNotification(true, "Please Add Data in Table.", "danger");
  //   }
  // };


  const handleEditAction = (e, id) => {
    e.preventDefault();

    if (!dashboardName) {
        props.setNotification(true, "Please Enter Dashboard Name.", "danger");
        return;
    }

    // Process API data
    const apiDetails = data?.results?.flatMap((item) =>
        item.details.map((detail) => ({
            name: detail?.name || "",
            type: detail?.type || "Card",
            template: detail?.template?.id ? Number(detail.template?.id) : "",
            field: detail?.field?.id ? Number(detail.field?.id) : "",
            function: detail?.function || "",
            state: detail?.state?.id ? Number(detail.state?.id) : "",
            colour: detail?.colour || "",
            icon: detail?.icon || "",
            individual: detail?.individual || "",
        }))
    ) || [];

    // Process table data
    const tableDetails = tabledata.map((data) => ({
        name: data[0] || "",
        type: data[1] || "Card",
        template: data[2] ? Number(data[2]) : "",
        field: data[3] ? Number(data[3]) : "",
        function: data[4] || "",
        state: data[5] ? Number(data[5]) : "",
        colour: data[6] || "",
        icon: data[7] || "",
        individual: data[8] || "",
    }));

    // Combine API and table data while ensuring uniqueness
    const details = [...apiDetails, ...tableDetails].filter(
        (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name && t.field === value.field)
    );

    // Final request body
    const requestBody = {
        dashboard: {
            dashboard_name: dashboardName
        },
        details: details
    };

    console.log("Final RequestBody:", requestBody); // Debugging

    // Ensure there is data before making the API call
    if (details.length === 0) {
        props.setNotification(true, "Please Add Data in Table.", "danger");
        return;
    }

    props.updateCustomDashboard(props.token?.access, id, requestBody, () => {
        setTableDatashow([]); // Clear displayed data
        props.handleClose();
    });
};

  console.log('dataset',tabledatashow)
  return (
    <Modal
      size="xl"
      show={props.showModal}
      onHide={() => props.handleClose()}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Custom Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={4} className="p-4">
              <Form.Label>
                Dashboard Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter Dashboard Name"
                required
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
              />
            </Col>
            <Col md={12} className="px-4">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Template</th>
                    <th>Field</th>
                    <th>Function</th>
                    <th>State</th>
                    <th>Color</th>
                    <th>Icon</th>
                    <th>Individual</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter Name"
                        required
                        name="name" // Matches the state key
                        value={state.name} // Bound to the state
                        onChange={handleChange1} // Updates the state on change
                      />
                    </td>
                    <td>
                      <Form.Select
                        name="type"
                        value={state.type}
                        onChange={handleChange1}
                      >
                        <option value="">Select</option>
                        <option value="Card">Card</option>
                        <option value="Table">Table</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="template"
                        value={state.template}
                        onChange={(e) => {
                          handleChange1(e);
                          fieldHandle1(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {Array.isArray(props.formList?.data) && props.formList?.data.length > 0 ? (
                          props.formList?.data.map((template, index) => (
                            <option key={index} value={template.id}>{template.label}</option>
                          ))
                        ) : (
                          <option disabled>No templates available</option>
                        )}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="field"
                        value={state.field}
                        onChange={handleChange1}
                      >
 <option value="">Select</option>
                        {Array.isArray(allfield) && allfield.length > 0 ? (
                          allfield.map((field, index) => (

                            <option key={index} value={field.id}>{field.label}</option>
                          ))
                        ) : (
                          <option disabled>No Field available</option>
                        )}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="function"
                        value={state.type === "Table" ? "" : state.function}
                        onChange={handleChange1}
                        disabled={state.type === "Table"}
                      >
                        <option value="">Select</option>
                        <option value="Count">Count</option>
                        <option value="Max">Max</option>
                        <option value="Average">Average</option>
                        <option value="Total">Total</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="states"
                        value={state.states}
                        onChange={handleChange1}
                      >
                        <option value="">Select</option>
                        {localWorkflowStateList.length > 0 ? (
                          localWorkflowStateList.map((item, index) => (
                            <option key={index} value={item.id}>{item.label}</option>
                          ))
                        ) : (
                          <option disabled>No State available</option>
                        )}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select aria-label="Default select example" required size="sm" value={state.buttonType}
                        name='buttonType'
                        onChange={handleChange1}>
                        <option value="">Select</option>
                        {BUTTONS.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>{ele.label}</option>
                          )
                        })}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        required
                        size="sm"
                        name='icon'
                        value={state.icon}
                        onChange={handleChange1}
                      >
                        <option value="">Select</option>
                        {dashboardIcon.map((ele, index) => (
                          <option value={ele.value} key={index}>
                            {ele.icon}
                          </option>
                        ))}
                      </Form.Select>

                    </td>
                    <td>
                      <Form.Select
                        name="indivisual"
                        value={state.indivisual}
                        onChange={handleChange1}
                        required

                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>

                      </Form.Select>
                    </td>
                    <td>
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={addData}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>

                  {data?.results?.map((item) =>
                    item.details.map((detail) => (

                      <tr key={detail.id}>
                        <td>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            value={detail.name || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          />
                        </td>
                        <td>
                          <Form.Select
                            name="type"
                            value={detail.type || "Card"}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="Card">Card</option>
                            <option value="Table">Table</option>
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="template"
                            value={detail?.template?.id || ""} // Ensure it matches the `id` of the selected template
                            onChange={(e) => {
                              const selectedValue = e.target.value; // Get the selected value
                              const selectedTemplate = props.formLists?.data?.find(
                                (template) => template.id == selectedValue // Find the corresponding template object
                              );

                              handleChange(
                                { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
                                item.id,
                                detail.id
                              );
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {props.formLists?.data?.map((template, index) => (
                              <option key={index} value={template.id}>
                                {template.label}
                              </option>
                            ))}
                          </Form.Select>

                        </td>

                        <td>
                          <Form.Select
                            name="field"
                            value={detail?.field?.id || ""}
                            onChange={(e) => {
                              const selectedValue = e.target.value; // Get the selected value
                              const selectedTemplate = props.fields?.find(
                                (field) => field.id == selectedValue // Find the corresponding template object
                              );

                              handleChange(
                                { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
                                item.id,
                                detail.id
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {props.fields?.map((field, index) => (
                              <option key={index} value={field.id}>
                                {field.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="function"
                            value={detail.function || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            <option value="Count">Count</option>
                            <option value="Max">Max</option>
                            <option value="Average">Average</option>
                            <option value="Total">Total</option>
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="state"
                            value={detail?.state?.id || ""}
                            // onChange={(e) => handleChange(e, item.id, detail.id)}
                            onChange={(e) => {
                              const selectedValue = e.target.value; // Get the selected value
                              const selectedTemplate = props.localWorkflowStateList?.find(
                                (workflow) => workflow.id == selectedValue // Find the corresponding template object
                              );

                              handleChange(
                                { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
                                item.id,
                                detail.id
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {props.localWorkflowStateList.map((state, index) => (
                              <option key={index} value={state.id}>
                                {state.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="colour"
                            value={detail.colour || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            {BUTTONS.map((btn, index) => (
                              <option key={index} value={btn.value}>
                                {btn.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="icon"
                            value={detail.icon || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            {dashboardIcon.map((icon, index) => (
                              <option key={index} value={icon.value}>
                                {icon.icon}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="individual"
                            value={detail.individual || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </Form.Select>
                        </td>
                        <td> <Button
                                                            title="Delete"
                                                            className="m-1"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => handleDelete(item.id, detail.id)}
                                                        >
                                                            <i
                                                                className="fa fa-trash-o"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button></td>
                      </tr>

                    ))
                  )}

                  {tabledatashow.map((data, index) => (
                    <tr key={index}>
                      <td>{data[0]}</td>
                      <td>{data[1]}</td>
                      <td>{data[2]}</td>
                      <td>{data[3]}</td>
                      <td>{data[4]}</td>
                      <td>{data[5]}</td>
                      <td>{data[6]}</td>
                      <td>{data[7]}</td>
                      <td>{data[8]}</td>
                      <td> <Button
                                                            title="Delete"
                                                            className="m-1"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => handlenewdataDelete(index)}
                                                        >
                                                            <i
                                                                className="fa fa-trash-o"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col md={12} className="d-flex justify-content-end p-4">
              <Button
                variant="outline-success"
                size="sm"
                onClick={(e) => handleEditAction(e, props.dashboardData?.results?.[0]?.id)}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  token: state.usersReducer.token,
  formList: state.formsReducer.formList,
  fields: state.formsReducer.fields,

  form: state.formsReducer.form,
  workflowStateList: state.workflowReducer.workflowStateList,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomDashboard: (accessToken, payload) =>
    dispatch(getCustomDashboard(accessToken, payload)),
  updateCustomDashboard: (accessToken, id, requestBody,fun) => dispatch(updateCustomDashboard(accessToken, id, requestBody,fun)),
  getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
  getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
  fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
  setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDashboardUpdateModal);
