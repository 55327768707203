import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { fetchAllFormsList, fetchData, fetchFields, fetchForms, updateFormData, workflowActionComment } from '../action';
import { formateDate, getFieldValue, validateForm } from '../../../lib/utils';
import CustomFieldHandler from '../../customforms/customfields/FieldHandler';
import FieldHandler from './fields/FieldHandler';
import { setNotification } from '../../common/action';

class TemplateViewDetails extends Component {
    constructor(props) {
        
        super(props)

        this.state = {
           
        }
    }
 
    componentDidMount() {
      
        // this.props.getFields(this.props.token.access, this.props.tempid);
        // this.props.getData(this.props.token.access, this.props.formid);
        
        // this.props.getForm(this.props.token?.access, this.props.tempid);
       
   

    }

   
    render() {
       
        return (
            <div>
                <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title > <h5>{this.props?.form?.label}</h5>
                                            <h6 style={{ color: "#6d6e92", fontFamily: "-moz-initial", fontSize: 16 }}>{this.props?.form?.description}</h6></Modal.Title>
                        
                    </Modal.Header>
                    <Modal.Body >
                    <div style={{ height: "auto", }}>
                        <Row>
                            
                        
                                                {this.props.fields?.map((field, index) => {
                                                    if (field?.type !== "OCR_VIEW") {

                                                        return (
                                                            <Col md = {3}>
                                                            <div key={index} className="m-3" >
                                                                <h6 style={{ color: "#6d6e92", fontFamily: "-moz-initial", fontSize: 16 }}>{field?.label}</h6>
                                                                <div style={{ fontSize: 16, marginTop: -5 }}>
                                                                    {getFieldValue(field, this.props.data?.data)}
                                                                </div>
                                                                {/* <hr /> */}
                                                            </div>
                                                            </Col>
                                                        )
                                                    }

                                                })}
                                                </Row>
                                            </div>
                                            <Row className='pt-2'>
                                                <hr/>
                                                <Col md="6">
                                                    <strong>Last Modified</strong> : {formateDate(this.props.data?.date_updated)}
                                                </Col>
                                                <Col md="6">
                                                    <strong>Added By</strong> : {this.props.data?.user === null ? 'Anonymous' : this.props.data?.user?.name}
                                                </Col>
                                            </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        workflowaction: state.formsReducer.workflowAction,
        data: state.formsReducer.data,
        fields: state.formsReducer.fields,
        profileData: state.usersReducer.profileData,
        form: state.formsReducer.form,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        workflowActionComment: (accessToken, requestBody) => dispatch(workflowActionComment(accessToken, requestBody)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        // getWorkflowAction: (accessToken, formId) => dispatch(getWorkflowAction(accessToken, formId)),
        updateData: (accessToken, templateId, requestBody, id, navigate) => dispatch(updateFormData(accessToken, templateId, requestBody, id, navigate)),
        getAllForms: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
        getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateViewDetails);