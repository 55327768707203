import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchFormMembers, updateFormMember, fetchForms, deleteFormMember } from '../action';
import AddFormMemberModal from './AddFormMemberModal';
import NameInitialPic from '../../users/components/NameInitialPic';
import DeleteModal from '../../common/components/DeleteModal';
import { fetchProjectDetails } from '../../project/action';

class FormMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddFormMemberModal: false,
            showDeleteModal: false,
            formId: this.props.params.id,
            label: '',
            description: '',
            show: false,
            search: '',
            filteredMembers: [],
            options: ['OWNER', 'MAINTAINER', 'REPORTER', 'INDIVIDUAL'],
        };
    }

    componentDidMount() {
        this.props.getFormMembers(this.props.token.access, this.props.params.id);
        this.props.fetchForms(this.props.token.access, this.props.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formMembers !== this.props.formMembers) {
            this.setState({
                filteredMembers: this.props.formMembers?.results || [],
            });
        }
        if (prevProps.formDetails !== this.props.formDetails && this.props.formDetails.project !== null) {
            this.props.fetchProjectDetails(this.props.token.access, this.props.formDetails.project);
        }
    }

    deleteAction(event) {
        this.props.removeMember(this.props.token.access, event.id, this.props.params.id);
    }

    handleUpdateAction(requestBody) {
        this.props.updateFormMember(this.props.token.access, requestBody);
    }

    handleSearchAction() {
        const { search } = this.state;
        const filteredMembers = this.props.formMembers?.results.filter((member) =>
            member.user.name.toLowerCase().includes(search.toLowerCase()) ||
            member.user.email.toLowerCase().includes(search.toLowerCase())
        );
        this.setState({ filteredMembers });
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row className="d-flex justify-content-end">
                                    <Col>
                                        <h5>Form Members</h5>
                                        <h3 style={{ fontSize: 11 }}>
                                            Project: {this.props.projectDetails.name} | Form: {this.props.formDetails.label}
                                        </h3>
                                    </Col>
                                    <Col md="2">
                                        <Button
                                            onClick={() => this.setState({ showAddFormMemberModal: true })}
                                            className="full-width"
                                            size="sm"
                                        >
                                            Add Member
                                            <i className="fa fa-user-plus ms-4" aria-hidden="true"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="font-medium">
                                <Row>
                                    <Col md="10">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder="Search Members"
                                            required
                                            value={this.state.search}
                                            onChange={(event) => this.setState({ search: event.target.value })}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Button
                                            className="full-width"
                                            variant="success"
                                            onClick={() => this.handleSearchAction()}
                                            size="sm"
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="mt-4 font-medium">
                                    {this.state.filteredMembers.length > 0 && (
                                        <DataTable
                                            totalItemsCount={this.state.filteredMembers.length}
                                            headers={['Member Name', 'Email', 'Role', 'Action']}
                                            body={this.state.filteredMembers.map((row, index) => {
                                                return [
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div
                                                            style={{
                                                                height: 40,
                                                                width: 40,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {row.user.profile ? (
                                                                <img src={row.user.profile} alt="user" />
                                                            ) : (
                                                                <NameInitialPic name={row.user.name} fontSize={22} />
                                                            )}
                                                        </div>
                                                        <span className="ms-3">{row.user.name}</span>
                                                    </div>,
                                                    row.user.email,
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        defaultValue={row.level}
                                                        onChange={(e) =>
                                                            this.handleUpdateAction({
                                                                ID: row.id,
                                                                user: row.user.id,
                                                                level: e.target.value,
                                                                template: this.props.params.id,
                                                            })
                                                        }
                                                        disabled={index === 0}
                                                    >
                                                        {this.state.options.map((option, idx) => (
                                                            <option value={option} key={idx}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </Form.Select>,
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({ showDeleteModal: true, selectedData: row })
                                                        }
                                                        disabled={index === 0}
                                                        variant="outline-danger"
                                                        size="sm"
                                                        className="m-1"
                                                    >
                                                        REMOVE MEMBER
                                                    </Button>,
                                                ];
                                            })}
                                        />
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                        <DeleteModal
                            modalText={'Remove Member ?'}
                            showModal={this.state.showDeleteModal}
                            handleClose={() => this.setState({ showDeleteModal: false })}
                            deleteAction={(status) => {
                                if (status) {
                                    this.deleteAction(this.state.selectedData);
                                }
                            }}
                            selectedData={this.state.selectedData}
                        />
                        <AddFormMemberModal
                            showModal={this.state.showAddFormMemberModal}
                            handleClose={() => this.setState({ showAddFormMemberModal: false })}
                            formId={this.state.formId}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    formDetails: state.formsReducer.form,
    projectDetails: state.projectReducer.projectDetails,
    formMembers: state.formsReducer.formMembers,
    token: state.usersReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
    removeMember: (accessToken, memberId, formId) => dispatch(deleteFormMember(accessToken, memberId, formId)),
    fetchForms: (accessToken, formId) => dispatch(fetchForms(accessToken, formId)),
    updateFormMember: (accessToken, requestBody) => dispatch(updateFormMember(accessToken, requestBody)),
    getFormMembers: (accessToken, formId, pageNumber) => dispatch(fetchFormMembers(accessToken, formId, pageNumber)),
    fetchProjectDetails: (accessToken, projectId) => dispatch(fetchProjectDetails(accessToken, projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormMembers);
