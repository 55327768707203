import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchWorkflowStateList } from '../workflows/action';
import { fetchFields, fetchForms, fetchFormsList } from '../forms/action';
import { createCustomLink, deleteCustomLink, fetchAllCustomLink, fetchAllCustomLinkById } from './action';
import DataTable from '../common/components/DataTable';
import CustomLinkUpdateModal from './CustomLinkUpdateModal';
import DeleteModal from '../common/components/DeleteModal';
import Select from 'react-select';


const CustomLink = (props) => {
    const [localWorkflowStateList, setLocalWorkflowStateList] = useState([]);
    const [selectedData, setselectedData] = useState([]);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [state, setState] = useState({
        link: '',
        type: '',
        template: '',

        states: '',
        showUpdateModal: false,
        selectedFields: [],


    })
    const handleChange = (e) => {

        const { name, value } = e.target
        setState((prevstate) => ({
            ...prevstate,
            [name]: value
        }))
    }
    useEffect(() => {
        props.fetchAllCustomLink(props.token?.access)
    }, [props.token?.access])
    useEffect(() => {
        props.getFormList(props.token?.access);

    }, [props.token?.access]);
    const fieldHandle = useCallback(async (formId, callback) => {
        try {
            // Fetch fields for the given formId
            if (formId) {

                const fieldsResponse = await props.getFields(props.token?.access, formId, 1);
                if (callback) {
                    callback(fieldsResponse?.data || []);
                }
            }


            // Execute callback with the response data

        } catch (error) {
            console.error("Error fetching fields:", error);

            // Execute callback with an empty array in case of error
            if (callback) {
                callback([]);
            }
        }

        // Optionally fetch the form
        try {
            if (formId) {
                await props.getForm(props.token?.access, formId);
            }

        } catch (error) {
            console.error("Error fetching form:", error);
        }
    }, [props.token?.access, props.getFields, props.getForm]);
    //adding fields acording to template 
    useEffect(() => {
        if (state.template !== "") {
            props.getFields(props.token?.access, state.template);
        }

    }, [state.template]);

    useEffect(() => {
        if (props.form?.workflow) {
            props.fetchWorkflowStateList(props.token?.access, props.form.workflow);
        }
        else {
            setState((prevstate) => ({
                ...prevstate,
                states: ""
            }))
            setLocalWorkflowStateList([])
        }

    }, [props.form, props.token?.access]);
    useEffect(() => {
        if (props.workflowStateList?.results) {
            setLocalWorkflowStateList(props.workflowStateList.results);
        }
    }, [props.workflowStateList]);

    // Handle form submit
    const handleCreateAction = (e) => {

        e.preventDefault();
        const { token } = props;
        const requestBody = {
            template: state.template,
            state: state.states,
            data_display: state.type,
            label: state.link,
            short_order: formatLabels(state.selectedFields),

        }

        if (token && token.access) {

            props.createCustomLink(token.access, requestBody);
            setState({
                link: '',
                type: '',
                template: '',
                states: '',
                selectedFields: [],
            })
        } else {
            console.error('Token is missing or invalid');
        }
    };
    const handleEditModal = (id) => {

        setState((prev) => ({
            ...prev,
            showUpdateModal: true,
        }));


        props.fetchAllCustomLink(props.token?.access);


        const selected = props.customlink?.find((data) => data.id === id);
        if (selected) {
            setselectedData(selected);
        } else {
            console.warn("Selected data not found in custom link list.");
        }
    };

    const deleteAction = (id) => {
        props.deleteCustomLink(props.token?.access, id);
        // setselectedData(null); // Reset selectedData after deletion
    };

    const feildoption = []
    props.fields?.map((data) => {
        feildoption.push({ label: data.label, value: data.label })
    })
    const formatLabels = (array) => {
        return array.map((item) => item.label).join(', ');
    };

    return (
        <div className="main-content-container p-4 container-fluid">
            <Row>
                <Col>
                    <p>Custom Link</p>
                    <Card>
                        <Card.Body className="font-medium">
                            <Form onSubmit={handleCreateAction}>
                                <Row>
                                    <Col className="">
                                        <Form.Label>
                                            Link Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name='link'
                                            placeholder="Enter Link Name"
                                            required
                                            value={state.link}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Template <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            name="template"
                                            value={state.template}
                                            onChange={(e) => {
                                                handleChange(e);
                                                fieldHandle(e.target.value);
                                            }}
                                        >
                                            <option value="">Select</option>
                                            {Array.isArray(props.formList?.data) && props.formList?.data.length > 0 ? (
                                                props.formList?.data.map((template, index) => (
                                                    <option key={index} value={template.id}>{template.label}</option>
                                                ))
                                            ) : (
                                                <option disabled>No templates available</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Sort By
                                        </Form.Label>
                                        <Select
                                            placeholder="Select Fields"
                                            onChange={(selected) => setState({ ...state, selectedFields: selected })}
                                            value={state.selectedFields} // Ensure value is compatible with isMulti
                                            isDisabled={state.template === "" ? true : false}
                                            isMulti
                                            name="group"
                                            options={feildoption}
                                            className="basic-multi-select"
                                            classNamePrefix="select Group"
                                        />

                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            State
                                        </Form.Label>
                                        <Form.Select
                                            name="states"
                                            value={state.states}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            {localWorkflowStateList.length > 0 ? (
                                                localWorkflowStateList.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.label}</option>
                                                ))
                                            ) : (
                                                <option disabled>No State available</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Type
                                        </Form.Label>
                                        <Form.Select
                                            name="type"
                                            value={state.type}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Table">Table</option>
                                            <option value="Table with Checkbox">Table With Checkbox</option>
                                            <option value="Table with State">Table With States</option>
                                        </Form.Select>
                                    </Col>
                                    <Col className="m-4">
                                        <Button type="submit" variant="outline-success" size="sm">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                            <div className="mt-3 font-medium">

                                {props.customlink && props.customlink?.length > 0 && (
                                    <DataTable
                                        totalItemsCount={props.customlink.count}
                                        headers={['Link Name', 'Action']}
                                        body={props.customlink?.map((row, index) => {
                                            return [
                                                row.label,
                                                <Row key={index}>
                                                    <Col md="2">
                                                        <Button
                                                            type='button'
                                                            title="Edit"
                                                            className="m-1"
                                                            variant="info"
                                                            size="sm"
                                                            onClick={() => {
                                                                handleEditModal(row.id)
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-pencil"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button>

                                                    </Col>
                                                    <Col md="2">
                                                        <Button
                                                            title="Delete"
                                                            className="m-1"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => {
                                                                setshowDeleteModal(true);
                                                                setselectedData(row.id); // Set selected data correctly
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-trash-o"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button>
                                                    </Col>
                                                </Row>,
                                            ];
                                        })}
                                    />
                                )}
                            </div>

                            <CustomLinkUpdateModal

                                showModal={state.showUpdateModal}
                                handleClose={() => setState((prev) => ({ ...prev, showUpdateModal: false }))}
                                customlinkdata={selectedData}
                            />

                            <DeleteModal
                                modalText="Delete ?"
                                showModal={showDeleteModal}
                                handleClose={() => setshowDeleteModal(false)}
                                deleteAction={(status) => {
                                    if (status !== undefined && status === true) {
                                        deleteAction(selectedData); // Pass selectedData to delete
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    formList: state.formsReducer.formList,
    form: state.formsReducer.form,
    fields: state.formsReducer.fields,
    workflowStateList: state.workflowReducer.workflowStateList,
    customlink: state.customLinkReducer.customlink,
});

const mapDispatchToProps = (dispatch) => ({
    getFormList: (accessToken) => dispatch(fetchFormsList(accessToken)),
    getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
    getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
    fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),

    createCustomLink: (accessToken, requestBody) => dispatch(createCustomLink(accessToken, requestBody)),
    fetchAllCustomLink: (accessToken) => dispatch(fetchAllCustomLink(accessToken)),
    fetchAllCustomLinkById: (accessToken, id, fun) => dispatch(fetchAllCustomLinkById(accessToken, id, fun)),
    deleteCustomLink: (accessToken, id) => dispatch(deleteCustomLink(accessToken, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLink);
