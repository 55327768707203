import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Table } from 'react-bootstrap';

import { createAssignTemplate, deleteAssignTemplate, fetchAllAssignTemplate } from '../../groupManagement/action';
import { fetchWorkflowStateList, fetchconfigureWorkflow } from '../../workflows/action';
import { setNotification } from '../../common/action';
import { fetchForms, fetchFormsList } from '../../forms/action';
import DeleteModal from '../../common/components/DeleteModal';

const AssignTemplate = (props) => {
    const { showModal, handleClose, token, formList, groupId } = props;
    const [templates, setTemplates] = useState("");
    const [selectedData, setselectedData] = useState(null);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [apidata, setApidata] = useState([])
    const [member, setMember] = useState("")
    // const [updateTrigger, setUpdateTrigger] = useState(false);
    useEffect(() => {
        if (token?.access) {
            props.getFormList(token.access);
        }
    }, [token?.access]);

    const templateChange = (e) => {
        const selectedTemplate = e.target.value;
        setTemplates(selectedTemplate);

    };
    useEffect(() => {
        if (groupId && props.token?.access) {
           
            props.fetchAllAssignTemplate(props.token.access, groupId, (response) => {
                setApidata(response); 
            });
        }
    }, [props.token?.access,groupId]);
    const refreshTableData = () => {
        if (groupId && props.token?.access) {
            props.fetchAllAssignTemplate(props.token.access, groupId, (response) => {
                setApidata(response);
            });
        }
    };
    const SubmitHandle = async (e) => {
        e.preventDefault();
        try {
            if (templates && member) {
               
                const requestBody = {
                    template: templates,
                    group: groupId,
                    member: member
                }
    
                
                await props.createAssignTemplate(props.token?.access, requestBody,()=>{
                    refreshTableData(); 
                });
    
                // setUpdateTrigger(prev => !prev); 
                
                setTemplates("");
                setMember("");
    
            
                // handleClose();
    
            } else {
                
                props.setNotification(true, "Field is Required", "danger");
            }
        } catch (error) {
            console.error("Error during template creation:", error);
        }
    }
    
    
    const deleteAction = async (data) => {
        try {
           
            await props.deleteAssignTemplate(props.token.access, data.id,groupId, () => {
                refreshTableData(); 
            });
    
            // setUpdateTrigger(prev => !prev); 
           
    
          
            setselectedData(null);
          
            // handleClose();
        } catch (error) {
            console.error("Error during delete and fetch:", error);
          
        }
    };
    
    

    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Assign Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Template Dropdown */}
                <Form.Select
                    placeholder="Select a Template"
                    aria-label="Default select example"
                    style={{
                        width: '100%',
                        padding: '0.375rem 0.75rem',
                        border: '1px solid #ced4da',
                        color: '#495057',
                        marginBottom: '15px',
                    }}
                    value={templates}
                    onChange={templateChange}
                >
                    <option value="">Select Template</option>
                    {formList?.data?.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.label}
                        </option>
                    ))}
                </Form.Select>

                <Form.Select
                    placeholder="Select a Member"
                    aria-label="Default select example"
                    style={{
                        width: '100%',
                        padding: '0.375rem 0.75rem',
                        border: '1px solid #ced4da',
                        color: '#495057',
                        marginBottom: '15px',
                    }}
                    value={member}
                    onChange={(e) => setMember(e.target.value)}
                >
                    <option value="">Select Member</option>
                    <option value="OWNER">Owner</option>
                    <option value="INDIVIDUAL">Individual</option>
                    <option value="MAINTAINER">Maintainer</option>
                    <option value="REPORTER">Reporter</option>

                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} variant="warning" size="sm">
                    CANCEL
                </Button>
                <Button variant="success" size="sm" onClick={SubmitHandle}>
                    ADD
                </Button>
            </Modal.Footer>
            <div style={{ padding: "10px 15px" }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>

                            <th>Template</th>
                            <th>Member</th>
                            <th>Action</th>


                        </tr>
                    </thead>
                    <tbody>
                        {apidata?.results?.map((item, index) =>
                            <tr key={index}>
                                <td>{item.template_name}</td>
                                <td>{item.member}</td>
                                <td>
                                    <Button
                                        title="Delete"
                                        className="m-1"
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                            setshowDeleteModal(true);
                                            setselectedData(item); // Set selected data correctly
                                        }}
                                    >
                                        <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                            style={{
                                                fontSize: '15px',
                                                color: 'white',
                                                padding: '2px 3px',
                                            }}
                                        ></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <DeleteModal
                modalText="Delete ?"
                showModal={showDeleteModal}
                handleClose={() => setshowDeleteModal(false)}
                deleteAction={(status) => {
                    if (status !== undefined && status === true) {
                        deleteAction(selectedData); // Pass selectedData to delete
                    }
                }}
            />
        </Modal>
    );
};


const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    formList: state.formsReducer.formList,
    workflowStateList: state.workflowReducer.workflowStateList,
    form: state.formsReducer.form,
});

const mapDispatchToProps = (dispatch) => ({
    getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
    fetchconfigureWorkflow: (accessToken, template_id, state_id, fun) => dispatch(fetchconfigureWorkflow(accessToken, template_id, state_id, fun)),
    // configureWorkflow: (accessToken, template_id, state_id, requestBody) => dispatch(configureWorkflow(accessToken, template_id, state_id, requestBody)),
    setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
    fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
    getFields: (accessToken, formId) => dispatch(fetchForms(accessToken, formId)),
    createAssignTemplate: (accessToken, requestBody,fun) => dispatch(createAssignTemplate(accessToken, requestBody,fun)),
    fetchAllAssignTemplate: (accessToken, id, fun) => dispatch(fetchAllAssignTemplate(accessToken, id, fun)),
    deleteAssignTemplate: (accessToken, id,groupId, fun) => dispatch(deleteAssignTemplate(accessToken, id,groupId, fun))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignTemplate);
