import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";

import { FETCH_ALL_CUSTOMLINK } from "./actionType";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function fetchAllCustomLink(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/user_template_get`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                
                
                dispatch({ type: FETCH_ALL_CUSTOMLINK, payload: successResponse })
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function fetchAllCustomLinkById(accessToken,id,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/user_template_get/${id}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                
                
               fun(successResponse)
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}
export function createCustomLink(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/user_template/create/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Custom Link Created successfully", "success"));
                dispatch(fetchAllCustomLink(accessToken))
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function deleteCustomLink(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/user_template_delete/${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Custom Link deleted Successfully', 'danger'))
                dispatch(fetchAllCustomLink(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}


export function updateCustomLink(accessToken, id,requestBody,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/user_template/${id}/update/`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch(setNotification(true, "Custom Link Updated successfully", "success"));
                // dispatch(fetchAllGroups(accessToken))
                fun()
                dispatch(fetchAllCustomLink(accessToken))
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}