import React, { useEffect, useState ,useCallback} from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import {  Col, Row } from 'react-bootstrap';
import { fetchFields, fetchForms, fetchFormsList } from '../forms/action';
import { fetchWorkflowStateList } from '../workflows/action';
import { updateCustomLink } from './action';
import Select from 'react-select';


const CustomLinkUpdateModal = (props) => {
    const { showModal, handleClose,customlinkdata  } = props;
  
    const [localWorkflowStateList, setLocalWorkflowStateList] = useState([]);

    const [state, setState] = useState({
        link: '',
        type: '',
        template: '',
       
        states: '',
        showUpdateModal: false,
        selectedFields: [],
    })
    useEffect(() => {
        if (customlinkdata) {
            console.log(customlinkdata,'customlinkdata')
            
            setState({
                link: customlinkdata?.label || "",
                type: customlinkdata?.data_display || "",
                template: customlinkdata?.template || "",
                states: customlinkdata?.state || "",
                selectedFields: customlinkdata?.short_order
                ? customlinkdata.short_order.split(',').map((item) => ({ label: item, value: item }))
                : []
            });
        }
    }, [customlinkdata]);

    const handleChange = (e) => {

        const { name, value } = e.target
        setState((prevstate) => ({
            ...prevstate,
            [name]: value
        }))
    }
 


useEffect(() => {
    props.getFormList(props.token?.access);
if(state.template){
    props.getFields(props.token?.access, state.template, 1);
    props.getForm(props.token?.access, state.template);
}
    
}, [props.token?.access,state.template]);


const fieldHandle = useCallback(async (formId, callback) => {
    try {
      // Fetch fields for the given formId
      const fieldsResponse = await props.getFields(props.token?.access, formId, 1);
      
      // Execute callback with the response data
      if (callback) {
        callback(fieldsResponse?.data || []);
      }
    } catch (error) {
      console.error("Error fetching fields:", error);
  
      // Execute callback with an empty array in case of error
      if (callback) {
        callback([]);
      }
    }
    
    // Optionally fetch the form
    try {
      await props.getForm(props.token?.access, formId);
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }, [props.token?.access, props.getFields, props.getForm]);

  useEffect(() => {
    if (props.form?.workflow) {
        props.fetchWorkflowStateList(props.token?.access, props.form.workflow);
    }
    else {
        setState((prevstate) => ({
            ...prevstate,
            states: ""
        }))
        setLocalWorkflowStateList([])
    }

}, [props.form, props.token?.access]);
useEffect(() => {
    if (props.workflowStateList?.results) {
        setLocalWorkflowStateList(props.workflowStateList.results);
    }
}, [props.workflowStateList]);
    const handleCreateAction = (e)=>{
        e.preventDefault();
      
        const requestBody = {
            template: state.template,
            state: state.states,
            data_display: state.type,
            label: state.link,
            short_order: formatLabels(state.selectedFields),
        }
        props.updateCustomLink(props.token?.access,customlinkdata.id,requestBody,()=>{
            props.handleClose();
        })
       

        // handleClose();
    
    }
    const feildoption = []
    props.fields?.map((data) => {
        feildoption.push({ label: data.label, value: data.label })
    })
    // const formatLabels = (array) => {
    //     return array?.map((item) => item.label).join(', ');
    // };
    const formatLabels = (array) => {
        if (!Array.isArray(array)) {
            
            return ""; 
        }
        return array.map((item) => item.label).join(', ');
    };
    return (
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Custom Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                                <Row>
                                    <Col md={6} className="">
                                        <Form.Label>
                                            Link Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name='link'
                                            placeholder="Enter Link Name"
                                            required
                                            value={state.link}
                                            onChange={handleChange}
                                        />
                                    </Col>
<Col md={6}>
<Form.Label>
                                            Template <span className="text-danger">*</span>
                                        </Form.Label>
<Form.Select
                                                            name="template"
                                                            value={state.template}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                fieldHandle(e.target.value);
                                                            }}
                                                        >
                                                            <option value="">Select</option>
                                                            {Array.isArray(props.formList?.data) && props.formList?.data.length > 0 ? (
                                                                props.formList?.data.map((template, index) => (
                                                                    <option key={index} value={template.id}>{template.label}</option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No templates available</option>
                                                            )}
                                                        </Form.Select>
</Col>
<Col>
                                        <Form.Label>
                                            Sort By 
                                        </Form.Label>
                                        <Select
                                            // placeholder="Select Fields"
                                            onChange={(selected) => setState({ ...state, selectedFields: selected || [] })}
                                            value={state.selectedFields} // Ensure value is compatible with isMulti
                                            isDisabled={state.template === "" ? true : false}
                                            isMulti
                                            name="group"
                                            options={feildoption}
                                            className="basic-multi-select"
                                            classNamePrefix="select Group"
                                        />

                                    </Col>
<Col md={6}>
<Form.Label>
                                            State <span className="text-danger">*</span>
                                        </Form.Label>
<Form.Select
                                                            name="states"
                                                            value={state.states}
                                                            onChange={handleChange}
                                                        >
                                                            {localWorkflowStateList.length > 0 ? (
                                                                localWorkflowStateList.map((item, index) => (
                                                                    <option key={index} value={item.id}>{item.label}</option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No State available</option>
                                                            )}
                                                        </Form.Select>
</Col>
<Col md={6}>
<Form.Label>
                                            Type <span className="text-danger">*</span>
                                        </Form.Label>
<Form.Select
                                                            name="type"
                                                            value={state.type}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Table">Table</option>
                                                            <option value="Table with Checkbox">Table With Checkbox</option>
                                                            <option value="Table with State">Table With States</option>
                                                        </Form.Select>
</Col>
                                    
                                </Row>

                            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="warning" size="sm">
            Cancel
          </Button>
          <Button variant="success" size="sm" onClick={handleCreateAction}>
            Update
          </Button>
        </Modal.Footer>

               
      </Modal>
    );
  };
  

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    formList: state.formsReducer.formList,
    form: state.formsReducer.form,
    fields: state.formsReducer.fields,
    workflowStateList: state.workflowReducer.workflowStateList,
    
});

const mapDispatchToProps = (dispatch) => ({
    getFormList: (accessToken) => dispatch(fetchFormsList(accessToken)),
    getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
    getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
    fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
    updateCustomLink: (accessToken,id,requestBody,fun) => dispatch(updateCustomLink(accessToken,id,requestBody,fun))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLinkUpdateModal);
